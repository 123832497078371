@media (max-width: 800px) {
  .hide-t {
    display: none !important;
  }

  .columns.collapse-t {
    -webkit-column-count: 1 !important;
            column-count: 1 !important;
  }
}

@media (max-width: 400px) {
  .hide-p {
    display: none !important;
  }

  .columns.collapse-p {
    -webkit-column-count: 1 !important;
            column-count: 1 !important;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 80%;
  }
}

.grid {
  --grid-cols: 12;
  --grid-gaps: calc(var(--grid-cols) - 1);
  --col-size: calc(100%/var(--grid-cols));
  --gap-total: calc(var(--gap-space, 0px) * var(--grid-gaps));
  --gap-subst: calc(var(--gap-total) / var(--grid-cols));
  display: grid;
  grid-template-columns: repeat(var(--grid-cols), calc(var(--col-size) - var(--gap-subst)));
  grid-gap: 0px;
  grid-gap: var(--gap-space, 0px);
  grid-gap: 0px;
  gap: 0px;
  grid-gap: var(--gap-space, 0px);
  gap: var(--gap-space, 0px);
  grid-auto-flow: dense;
}

.span-1 {
  grid-column: span 1;
}

.start-1 {
  grid-column-start: 1;
}

.end-1 {
  grid-column-end: 1;
}

.start-y-1 {
  grid-row-start: 1;
}

.end-y-1 {
  grid-row-end: 1;
}

.span-2 {
  grid-column: span 2;
}

.start-2 {
  grid-column-start: 2;
}

.end-2 {
  grid-column-end: 2;
}

.start-y-2 {
  grid-row-start: 2;
}

.end-y-2 {
  grid-row-end: 2;
}

.span-3 {
  grid-column: span 3;
}

.start-3 {
  grid-column-start: 3;
}

.end-3 {
  grid-column-end: 3;
}

.start-y-3 {
  grid-row-start: 3;
}

.end-y-3 {
  grid-row-end: 3;
}

.span-4 {
  grid-column: span 4;
}

.start-4 {
  grid-column-start: 4;
}

.end-4 {
  grid-column-end: 4;
}

.start-y-4 {
  grid-row-start: 4;
}

.end-y-4 {
  grid-row-end: 4;
}

.span-5 {
  grid-column: span 5;
}

.start-5 {
  grid-column-start: 5;
}

.end-5 {
  grid-column-end: 5;
}

.start-y-5 {
  grid-row-start: 5;
}

.end-y-5 {
  grid-row-end: 5;
}

.span-6 {
  grid-column: span 6;
}

.start-6 {
  grid-column-start: 6;
}

.end-6 {
  grid-column-end: 6;
}

.start-y-6 {
  grid-row-start: 6;
}

.end-y-6 {
  grid-row-end: 6;
}

.span-7 {
  grid-column: span 7;
}

.start-7 {
  grid-column-start: 7;
}

.end-7 {
  grid-column-end: 7;
}

.start-y-7 {
  grid-row-start: 7;
}

.end-y-7 {
  grid-row-end: 7;
}

.span-8 {
  grid-column: span 8;
}

.start-8 {
  grid-column-start: 8;
}

.end-8 {
  grid-column-end: 8;
}

.start-y-8 {
  grid-row-start: 8;
}

.end-y-8 {
  grid-row-end: 8;
}

.span-9 {
  grid-column: span 9;
}

.start-9 {
  grid-column-start: 9;
}

.end-9 {
  grid-column-end: 9;
}

.start-y-9 {
  grid-row-start: 9;
}

.end-y-9 {
  grid-row-end: 9;
}

.span-10 {
  grid-column: span 10;
}

.start-10 {
  grid-column-start: 10;
}

.end-10 {
  grid-column-end: 10;
}

.start-y-10 {
  grid-row-start: 10;
}

.end-y-10 {
  grid-row-end: 10;
}

.span-11 {
  grid-column: span 11;
}

.start-11 {
  grid-column-start: 11;
}

.end-11 {
  grid-column-end: 11;
}

.start-y-11 {
  grid-row-start: 11;
}

.end-y-11 {
  grid-row-end: 11;
}

.span-12 {
  grid-column: span 12;
}

.start-12 {
  grid-column-start: 12;
}

.end-12 {
  grid-column-end: 12;
}

.start-y-12 {
  grid-row-start: 12;
}

.end-y-12 {
  grid-row-end: 12;
}

.span-13 {
  grid-column: span 13;
}

.start-13 {
  grid-column-start: 13;
}

.end-13 {
  grid-column-end: 13;
}

.start-y-13 {
  grid-row-start: 13;
}

.end-y-13 {
  grid-row-end: 13;
}

.span-14 {
  grid-column: span 14;
}

.start-14 {
  grid-column-start: 14;
}

.end-14 {
  grid-column-end: 14;
}

.start-y-14 {
  grid-row-start: 14;
}

.end-y-14 {
  grid-row-end: 14;
}

.span-15 {
  grid-column: span 15;
}

.start-15 {
  grid-column-start: 15;
}

.end-15 {
  grid-column-end: 15;
}

.start-y-15 {
  grid-row-start: 15;
}

.end-y-15 {
  grid-row-end: 15;
}

.span-16 {
  grid-column: span 16;
}

.start-16 {
  grid-column-start: 16;
}

.end-16 {
  grid-column-end: 16;
}

.start-y-16 {
  grid-row-start: 16;
}

.end-y-16 {
  grid-row-end: 16;
}

:root {
  --space-base: .25rem;
  --space-ratio: 2.5;
  --space-xs: var(--space-base);
  --space-s: calc(var(--space-ratio) * var(--space-xs));
  --space-m: calc(var(--space-ratio) * var(--space-s));
  --space-l: calc(var(--space-ratio) * var(--space-m));
  --space-xl: calc(var(--space-ratio) * var(--space-l));
  --space-xxl: calc(var(--space-ratio) * var(--space-xl));
  --space-px: 1px;
}

.gap-no > * {
  margin-bottom: calc(-1 * 1px);
  margin-bottom: calc(-1 * var(--space-px));
}

.gap-no > .gap-no {
  margin-bottom: 0;
}

.grid.gap-no,
.grid-auto.gap-no {
  --gap-space: 0px;
  margin-left: 1px;
  margin-left: var(--space-px);
}

.grid.gap-no > *,
.grid-auto.gap-no > * {
  margin-left: calc(-1 * 1px);
  margin-left: calc(-1 * var(--space-px));
}

.flex.gap-no {
  margin-left: 1px;
  margin-left: var(--space-px);
  margin-bottom: 0;
}

.flex.gap-no > * {
  margin-left: calc(-1 * 1px);
  margin-left: calc(-1 * var(--space-px));
}

.flex.gap-no > .span-twothird {
  flex-basis: calc(66.6666% + 1px);
  flex-basis: calc(66.6666% + var(--space-px));
}

.flex.gap-no > .span-half {
  flex-basis: calc(50% + 1px);
  flex-basis: calc(50% + var(--space-px));
}

.flex.gap-no > .span-third {
  flex-basis: calc(33.3333% + 1px);
  flex-basis: calc(33.3333% + var(--space-px));
}

.flex.gap-no > .span-quarter {
  flex-basis: calc(25% + 1px);
  flex-basis: calc(25% + var(--space-px));
}

.flex.gap-no > .span-fifth {
  flex-basis: calc(20% + 1px);
  flex-basis: calc(20% + var(--space-px));
}

.gap_xs:not(.grid):not(.grid-auto):not(.flex) > *:not(:first-child) {
  margin-top: .25rem;
  margin-top: var(--space-xs);
}

.gap_s:not(.grid):not(.grid-auto):not(.flex) > *:not(:first-child) {
  margin-top: calc(2.5 * .25rem);
  margin-top: var(--space-s);
}

.gap_m:not(.grid):not(.grid-auto):not(.flex) > *:not(:first-child) {
  margin-top: calc(2.5 * calc(2.5 * .25rem));
  margin-top: var(--space-m);
}

.gap_l:not(.grid):not(.grid-auto):not(.flex) > *:not(:first-child) {
  margin-top: calc(2.5 * calc(2.5 * calc(2.5 * .25rem)));
  margin-top: var(--space-l);
}

.gap-custom:not(.grid):not(.grid-auto):not(.flex) > *:not(:first-child) {
  margin-top: var(--gap-size);
}

[class*="grid"].gap_xs {
  --gap-space: var(--space-xs);
}

[class*="grid"].gap-h_xs {
  grid-row-gap: .25rem;
  grid-row-gap: var(--space-xs);
}

[class*="grid"].gap_s {
  --gap-space: var(--space-s);
}

[class*="grid"].gap-h_s {
  grid-row-gap: calc(2.5 * .25rem);
  grid-row-gap: var(--space-s);
}

[class*="grid"].gap_m {
  --gap-space: var(--space-m);
}

[class*="grid"].gap-h_m {
  grid-row-gap: calc(2.5 * calc(2.5 * .25rem));
  grid-row-gap: var(--space-m);
}

/* import "../node_modules/teutonic-css/includes/grid-auto";          // CSS grid alternative layout, container query style */

.grid-auto {
  display: grid;
  grid-gap: var(--space-s);
  grid-gap: var(--gap-space, var(--space-s));
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-auto-min, 8rem), var(--grid-auto-max, 1fr)));
  grid-auto-flow: dense;
}

.grid-auto.min_s {
  --grid-auto-min: 12rem;
}

.grid-auto.min_m {
  --grid-auto-min: 16rem;
}

.grid-auto.min_l {
  --grid-auto-min: 18rem;
}

.grid-auto.min_xl {
  --grid-auto-min: 22rem;
}

.grid-auto.min_xxl {
    --grid-auto-min: 32rem;
}

@media (max-width: 800px) {
  [class*="grid"].collapse-t {
    grid-template-columns: 1fr;
  }

  [class*="grid"] > .span-3-t {
    grid-column: span 3;
  }

  [class*="grid"] > .span-4-t {
    grid-column: span 4;
  }

  [class*="grid"] > .span-5-t {
    grid-column: span 5;
  }

  [class*="grid"] > .span-6-t {
    grid-column: span 6;
  }

  [class*="grid"] > .span-7-t {
    grid-column: span 7;
  }

  [class*="grid"] > .span-8-t {
    grid-column: span 8;
  }

  [class*="grid"] > .span-9-t {
    grid-column: span 9;
  }

  [class*="grid"] > .span-10-t {
    grid-column: span 10;
  }

  [class*="grid"] > .span-11-t {
    grid-column: span 11;
  }

  [class*="grid"] > .span-12-t {
    grid-column: span 12;
  }
}

@media (max-width: 400px) {
  [class*="grid"].collapse-p {
    grid-template-columns: 1fr;
  }

  [class*="grid"] > .span-6-p {
    grid-column: span 6;
  }

  [class*="grid"] > .span-7-p {
    grid-column: span 7;
  }

  [class*="grid"] > .span-8-p {
    grid-column: span 8;
  }

  [class*="grid"] > .span-9-p {
    grid-column: span 9;
  }

  [class*="grid"] > .span-10-p {
    grid-column: span 10;
  }

  [class*="grid"] > .span-11-p {
    grid-column: span 11;
  }

  [class*="grid"] > .span-12-p {
    grid-column: span 12;
  }
}

